<template>
  <div class="root-div mx-auto">
    <div class="py-20 mx-auto">
      <!--begin::Form-->
      <v-card class="login-div">
        <p class="h2 my-5 font-bold font-pink" >{{ isLogin ? 'Login' : 'Create your account'}}</p>
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-form-group label="Email">
            <b-form-input
              class="form-control form-control-solid p-2"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="feedback_email"
            />
            <b-form-invalid-feedback id="feedback_email">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Password">
            <b-input-group>
              <b-form-input
                class="form-control form-control-solid p-2"
                :type="showPassword ? 'text' : 'password'"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                aria-describedby="feedback_password"
              />
              <template v-slot:append>
                <v-btn icon @click="showPassword = !showPassword">
                  <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                </v-btn>
              </template>
            </b-input-group>
            <b-form-invalid-feedback id="feedback_password">
              Password is required and 6 characters at least.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <v-btn block large class="mt-5 text-capitalize" color="#EB4C60" @click="onSubmit">
            <span class="font-bold text-white font-15">{{ isLogin ? 'Login' : 'Create Account'}}</span>
          </v-btn>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

        <!-- Or -->
        <div class="m-3 d-flex align-items-center">
          <div class="black-line"></div>
          <span class="m-2">or continue with</span>
          <div class="black-line"></div>
        </div>

        <!-- Social Buttons -->
        <div class="d-flex justify-content-between">
          <v-btn class="w-25" @click="continueWithSocial(1)" outlined color="#A4A4A4">
            <img :src="assets.google" />
          </v-btn>
          <v-btn class="w-25" @click="continueWithSocial(2)" outlined color="#A4A4A4">
            <img :src="assets.facebook" />
          </v-btn>
          <v-btn class="w-25" @click="continueWithSocial(3)" outlined color="#A4A4A4">
            <img :src="assets.apple" />
          </v-btn>
        </div>

        <!-- Sign up -->
        <div class="mt-5 d-flex justify-content-center align-items-center">
          <span>{{ isLogin ? 'Don\'t have an account?' : 'Have an account?'}}</span>
          <v-btn class="text-capitalize" @click="isLogin = !isLogin" text color="#E54A5E">
            <u class="font-bold">{{ isLogin ? 'Sign up' : 'Log in'}}</u>
          </v-btn>
        </div>

        <!-- Terms -->
        <div class="mt-5 text-center">
          <span>By clicking <b>{{ isLogin ? 'Login' : 'Create Account' }}</b>, you agree to the</span>
          <div class="d-flex justify-content-center align-items-center">
            <v-btn class="text-capitalize p-0" @click="$router.push('/#policy')" text color="#E54A5E">
              <u>Terms of Service</u>
            </v-btn>
            <span>,&nbsp;</span>
            <v-btn class="text-capitalize p-0" @click="$router.push('/#policy')" text color="#E54A5E">
              <u>Privacy Policy</u>
            </v-btn>
            <span>&nbsp;and&nbsp;</span>
            <v-btn class="text-capitalize p-0" @click="$router.push('/#policy')" text color="#E54A5E">
              <u>Cookie Policy</u>
            </v-btn>
            <span>.</span>
          </div>
        </div>
      </v-card>
    </div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { showLoading } from '../../functions';
import { firebaseAuth, functions } from '../../main';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

import google from '@/assets/image/google.png';
import facebook from '@/assets/image/facebook.png';
import apple from '@/assets/image/apple.png';

export default {
  mixins: [validationMixin],
  name: "Login",
  components: {
    KTFooterExtended
  },
  data() {
    return {
      assets: {
        google,
        facebook,
        apple
      },
      form: {
        email: '',
        password: ''
      },
      isLogin: true,
      showPassword: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  mounted() {
    if (this.$store.state.myInfo !== null) {
      this.$router.push('/');
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    continueWithSocial(type) {
      let provider;
      if (type === 2) {
        provider = new FacebookAuthProvider();
      } else if (type === 3) {
//        provider = new OAuthProvider('apple.com');
      } else {
        provider = new GoogleAuthProvider();
      }
      if (!provider) {
        this.$toast.info('Coming soon ...');
        return;
      }
      provider.addScope('email');
      signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        let email;
        const user = result.user;
        if (user !== null) {
          email = user.email
          if (!email) {
            if (user.providerData && user.providerData.length > 0) {
              email = user.providerData[0].email
            }
          }
        }
        if (email) {
          const loader = showLoading(this, 1);
          this.loginTask(loader, email);
        } else {
          this.$toast.error('Unexpected error occurs!');
        }
      }).catch((error) => {
       this.$toast.error(error.code + ', ' + error.message);
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      const loader = showLoading(this, 1);
      if (this.isLogin) {
        signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(result => {
          this.loginTask(loader, email);
        }).catch(error => {
          loader.hide();
          if (error.code === 'auth/user-not-found') {
            this.$toast.error('Incorrect email address!');
          } else if (error.code === 'auth/wrong-password') {
            this.$toast.error('Incorrect password!');
          } else {
            this.$toast.error(error.message);
          }
        });
      } else {
        createUserWithEmailAndPassword(firebaseAuth, email, password)
        .then(result => {
          this.loginTask(loader, email);
        }).catch(error => {
          loader.hide();
          if (error.code === 'auth/email-already-in-use') {
            this.$toast.error('This email is already in use!');
          } else {
            this.$toast.error(error.message);
          }
        });
      }
    },
    loginTask(loader, email) {
      const params = {
        email: email,
      };
      const func = httpsCallable(functions, 'loginUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === -1100) {
          this.$toast.info(this.isLogin ? 'The user with this email does not exist! Please register first.' : 'Please register your information.');
          localStorage.setItem('emailForRegister', email);
          this.$router.push('/register');
        } else {
          const userInfo = JSON.parse(response.data);
          if (userInfo) {
            this.$toast.success('Logged in successfully.');
            this.$store.commit('set', ['myInfo', userInfo]);
            localStorage.setItem('myInfo', JSON.stringify(userInfo));
            if (this.$store.state.orderInfo === null) {
              this.$router.push('/');
            } else {
              this.$router.push('/booking');
            }
          } else {
            this.$toast.error('Unexpected error occurs!');
          }
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>
